@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Analysis {
    .Panel--header__right {
        button {
            display: flex;
            align-items: center;
            gap: layout.$padding--small;

            padding: layout.$padding--small;

            color: color.$black;
            font-size: font.$size--xsmall;

            background-color: color.$blue--light;
            box-shadow: none;

            &:hover {
                background-color: darken(color.$blue--light, 1);
            }

            svg {
                font-size: font.$size--small;
                stroke: color.$blue;
            }
        }
    }

}
