@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';

@import '@strategies/tokens';

$color_negative: #E59D64;
$color_positive: #80FAB1;

html, body, #root {
    height: 100%;
}

.EmbodiedCarbonApp {

    @import 'vendor';
    @import 'components/Analysis/Analysis';
    @import 'components/Assemblies/Assemblies';
    @import 'components/AssembliesAnalysis/AssembliesAnalysis';
    @import 'components/Assembly/Assembly';
    @import 'components/AssemblyRatioAnalysis/AssemblyRatioAnalysis';
    @import 'components/Component/Component';
    @import 'components/Insight/Insight';
    @import 'components/MenuBar/MenuBar';
    @import 'components/ModelHistory/ModelHistory';
    @import 'components/Startup/Startup';
    @import 'components/Types/Types';


    display: grid;
    gap: layout.$padding;
    grid-template-columns: 1.4fr 1fr 1fr;
    grid-template-rows: 3.125rem auto;

    height: 100%;
    padding: layout.$padding;

    ul {
        padding: 0;

        list-style: none;
    }

    .pill {
        flex: 0 0 max-content;

        height: auto;
        margin: auto;
        padding: layout.$padding--smallest;

        color: color.$black;
        font-size: font.$size--smallest;
        font-weight: font.$weight--medium;
        line-height: .9em;

        background: color.$blue--light;
        border-radius: layout.$border-radius--small;
    }

    .pill-wrapper {
        display: flex;
        align-items: center;

        padding: 0;
        margin: 0;
        text-align: right;

        min-width: 7.25rem;
        height: 100%;

        .pill {
            margin-right: 0;
        }
    }

    .Widget {
        padding: layout.$padding--small;

        background: color.$blue--lightest;
        border-radius: layout.$border-radius--small;

        & + .Widget {
            margin-top: layout.$padding--small;
        }

        > h4 {
            margin: 0;
            margin-bottom: layout.$padding--small;

            font-size: font.$size--small;
            font-weight: font.$weight--medium;
        }

        > div {
            padding: layout.$padding--small;

            background: color.$white;
            border-radius: layout.$border-radius--small;

            + div {
                margin-top: layout.$padding--small;
            }
        }
    }

}
