@use 'sass:math';
@use '@strategies/tokens/animation';
@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.visx-group {
    transition: transform animation.$speed--slow;
}

.RadioSlider__highlighter {
    transition: width animation.$speed, left animation.$speed;
}

.Panel {
    header {
        border: 2px solid color.$blue--lightest;
    }

    &--header__left .IconButton {
       pointer-events: none;
    }

    main {
        padding: layout.$padding;
    }
}

.Accordion {
    header {
        border: none;

        .Accordion__header {
            display: flex;
            align-items: center;
            gap: layout.$padding--small;

            height: auto;
            padding: 0;

            background: none;

            .ReactForm__Input {
                margin: 0;
                margin-left: auto;
            }
        }
    }

    &.open .Accordion__toggle svg {
        transform: rotate(90deg);
    }
}


.RadioSlider {
    text-transform: uppercase;

    &__option {
        padding: math.div(layout.$padding--smallest, 2) layout.$padding--smallest;
    }
}

.ReactForm {

    &__Input.small {
        max-width: 8rem;

        .ReactForm__Input {
            &-input {
                border-radius: layout.$border-radius;
                font-size: font.$size--xsmall;

                input, span {
                    padding: 6px;
                }
            }
        }
    }
}

.ReactLogin {
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    .login-panel > img {
        max-width: 250px;
    }
}
