@use '@strategies/tokens/animation';
@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.AssembliesAnalysis {

    main {
        display: flex;
        flex-direction: column;
        gap: layout.$padding;
        height: 100%;
    }

    .AssemblyRatioAnalysis {
        flex: 0 1 40px;
    }

    .treemap-container {
        flex: 1 1;
    }

    svg {
        position: absolute;
        left: 0px;
        top: 0px;

        border-radius: layout.$border-radius--small;
        overflow: hidden;
    }

    rect {
        stroke: color.$white;
        rx: layout.$border-radius--small;
        transition: all animation.$speed--slow;
    }

    text {
        font-size: font.$size--xsmall;
        font-weight: 500;

        transform: translate(.85rem, 1.5rem);

        &.bold {
            font-weight: 700;
        }

        &:nth-of-type(2) {
            font-size: font.$size--smallest;

            transform: translate(.85rem, 2.5rem);
        }

        &:nth-of-type(3) {
            font-size: font.$size--smallest;

            transform: translate(.85rem, 3.5rem);
        }
    }

    .selection {
        position: absolute;

        border: 2px solid color.$black;
        border-radius: layout.$border-radius--small;
        pointer-events: none;
        transition: all animation.$speed;
    }


}
