@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Assembly {
    display: flex;
    align-items: center;
    gap: layout.$padding--small;

    height: calc(1.125rem + (15px * 2));

    border-radius: layout.$border-radius;
    overflow: hidden;

    + .Assembly {
        margin-top: layout.$padding--small;
    }

    .color {
        flex: 0 1 1rem;
        display: block;

        height: 100%;
        width: 1rem;
    }

    h3 {
        flex: 1 1;
    }

    .ReactForm__Input {
        &-input {
            width: 13em;

            input, span {
                padding: layout.$padding--smallest;

                font-size: font.$size--xsmall;
            }

            input {
                margin: auto;
                border: 2px solid color.$white;
            }
        }
    }
}
