@use '@strategies/tokens/animation';
@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';

@keyframes grow {
    from {
        margin-right: -20px;
        opacity: 0;
        height: 0;
    }
    to {
        margin-right: 0;
        opacity: 1;
    }
}

@keyframes drop {
    from {
        margin-top: -20px;
        opacity: 0;
    }
    to {
        margin-top: 0px;
        opacity: 1;
    }
}

.ModelHistory {

    div {
        font-size: font.$size--smallest;
    }

    .insight {
        display: flex;
        align-items: center;
        gap: layout.$padding--small;

        span {
            color: color.$grey;
            font-size: font.$size--small;
        }
    }


    ul {
        max-height: calc(5.5 * #{layout.$padding--small} * 4 + (1.5 * #{font.$size--small}));
        padding-left: 0;

        overflow-y: auto;
    }

    li {
        display: flex;
        align-items: center;
        gap: layout.$padding--small;

        padding-left: layout.$padding;
        margin-bottom: layout.$padding--small;

        animation-name: drop;
        animation-duration: animation.$speed--slow;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &:last-of-type {
            margin-bottom: 0;
        }

        svg {
            margin: 0;
            font-size: font.$size--large;

            &.less {
                color: $color_positive;
                stroke-width: 4px;
            }
            &.greater {
                color: $color_negative;
                stroke-width: 4px;
            }
            &.equal {
                color: rgba(color.$black, .4);
            }
        }

        .pill {
            margin: 0;

            &.less {
                background: $color_positive;
            }
            &.greater {
                background: $color_negative;
            }
            &.equal {
                background: rgba(color.$black, .4);
            }
        }
    }

    .bar-chart {
        display: flex;
        flex-direction: row-reverse;
        gap: layout.$padding--small;

        height: 200px;
        padding: layout.$padding--large layout.$padding layout.$padding--small;

        overflow: hidden;
    }

    .bar {
        margin-top: auto;
        margin-bottom: 0;

        height: 0px;

        border-radius: 5px 5px 0 0;
        transition: height animation.$speed;

        animation-name: grow;
        animation-iteration-count: 1;
        animation-duration: animation.$speed--slow;
        animation-fill-mode: forwards;

        &.less {
            background: $color_positive;
        }
        &.greater {
            background: $color_negative;
        }
        &.equal {
            background: rgba(color.$black, .4);
        }
    }

}
