@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Insight {
    padding: layout.$padding--small;

    background: color.$blue--lightest;
    border-radius: layout.$border-radius--small;

    & + .Insight {
        margin-top: layout.$padding--small;
    }

    h4 {
        margin: 0;
        margin-bottom: layout.$padding--small;

        font-size: font.$size--small;
        font-weight: font.$weight--medium;
    }

    div {
        display: flex;
        align-items: center;
        gap: layout.$padding--small;

        font-size: font.$size--large;

        svg {
            &.less {
                color: $color_positive;
            }
            &.greater {
                color: $color_negative;
            }
            &.equal {
                color: rgba(color.$black, .4);
            }
        }

        span {
            display: inline-block;
            margin: auto;

            &:first-of-type {
                margin-right: 0;
            }

            &:last-of-type {
                margin-left: 0;
            }
        }
    }
}
