@use '@strategies/tokens/layout';


.Types {

    padding: layout.$padding;

    header .header {
        display: flex;
        gap: layout.$padding--small;
        align-items: center;

        padding: 4px 0;
    }

    .ReactForm__Input {
        &-input {
            width: 10em;
        }
    }
}
