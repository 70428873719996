@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Startup {
    .Modal--gutter {
        cursor: initial;
    }

    .Modal--window {
        min-width: 570px;

        main {
            width: max-content;
            max-width: 900px;
            margin: auto;
        }
    }

    header, footer {
        display: none;
    }

    img {
        display: block;

        height: 2rem;
        margin: layout.$padding--large auto layout.$padding--large * 2;
    }

    p {
        margin: layout.$padding--large 0;

        font-weight: 400;
    }

    .get-started {
        margin: layout.$padding--large 0;
        padding: layout.$padding 0;

        border-top: 3px solid color.$blue--lightest;
        border-bottom: 3px solid color.$blue--lightest;
    }

    .get-started-options {
        display: flex;
        gap: layout.$padding;
        margin: layout.$padding 0;

        .Button {
            flex: 1 1;
            width: auto;
            margin: 0;
            text-align: center;
            padding: layout.$padding--large;

            svg {
                display: block;
                margin: auto layout.$padding;
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }

    h2, h3 {
        margin: 0;
        font-weight: 500;
    }

    h2 {
        font-size: font.$size--large;
    }

    h3 {
        font-size: font.$size--small;
    }

    section {
        text-align: center;
    }

    .continue .Button {
        width: 100%;
        margin: layout.$padding--large auto;
        align-items: center;

        svg {
            top: -2px;
            vertical-align: middle;
            margin: auto layout.$padding;
        }
    }

    .ReactForm__Input {
        margin: layout.$padding auto;

        label {
            text-transform: initial;
            font-size: font.$size--large;
            margin-bottom: layout.$padding--large;
        }

        .ReactForm__Input-input {
            max-width: 14rem;
            margin: auto;
        }

        &.columned {
            .ReactForm__Label {
                display: flex;
                gap: layout.$padding;

                max-width: 20rem;
                margin: auto;

                > * { margin: auto; }

                label {
                    flex: 1 1;
                    font-size: font.$size--regular;
                }

                .ReactForm__Input-input {
                    max-width: 8rem;
                }
            }
        }
    }
}
