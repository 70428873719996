@use '@strategies/tokens/animation';
@use '@strategies/tokens/color';
@use '@strategies/tokens/layout';


.AssemblyRatioAnalysis {

    .ratios {
        display: flex;
        gap: 2px;

        height: 100%;

        div {
            height: 100%;

            border: 2px solid transparent;
            border-radius: layout.$border-radius--small;
            cursor: pointer;
            transition: width animation.$speed, background-color animation.$speed--slow, border-color animation.$speed;

            &:hover {
                border-color: rgba(grey, .2);
            }
        }
    }

    .selection {
        position: absolute;
        top: 0;

        height: 100%;

        border: 2px solid color.$black;
        border-radius: layout.$border-radius--small;
        pointer-events: none;
        transition: left animation.$speed, top animation.$speed;
    }

}
