@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Assemblies {

    padding: layout.$padding;

    background: color.$blue--lightest;
    border-radius: layout.$border-radius--large;

}
