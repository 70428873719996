@use 'sass:math';
@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';


.Component {
    display: flex;
    gap: layout.$padding--small;

    + .Component {
        margin-top: layout.$padding--small;
    }

    h4 {
        flex: 1 0;

        margin: auto;
        margin-left: layout.$padding--large;

        font-size: font.$size--small;
    }

    select {
        margin: auto;
        padding: math.div(layout.$padding--smallest, 2) layout.$padding;

        color: color.$black;
        font-size: font.$size--xsmall;

        background: color.$white;
        border: 2px solid color.$grey;
        border-radius: layout.$border-radius;
    }
}
