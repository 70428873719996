@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';

@import 'modals/InfoModal/InfoModal/';


.MenuBar {
    grid-column: span 3;

    &--ribbon-body {
        width: 100%;
    }

    .right {
        display: flex;
        align-items: center;

        margin: 0 0 0 auto;

        .Button {
            margin: auto layout.$padding--smallest;
            padding: 5px layout.$padding--small;
        }
    }

    h6 {
        margin: auto;
        margin-left: layout.$padding;

        font-size: font.$size--small;

        span {
            font-weight: 400;
        }
    }

}
